import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import {QuickbooksService} from './quickbooks.service';
import {ActivatedRoute, Router} from '@angular/router';

const TOKEN_KEY = 'auth-token';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    authenticationState = new BehaviorSubject(false);

    constructor(private storage: Storage, private plt: Platform,
        private quickbooksService: QuickbooksService, router: Router, route: ActivatedRoute) {
        this.plt.ready().then(() => {
            this.checkToken();
        });
    }

    checkToken() {
        this.storage.get(TOKEN_KEY).then(res => {
            if (res) {
                this.authenticationState.next(true);
            }
        });
    }

    login() {

        return new Promise(resolve => {
            return this.storage.set(TOKEN_KEY, 'Bearer 1234567').then(() => {
                this.authenticationState.next(true);
                resolve();
            });
        });
    }

    logout() {
        return new Promise(resolve => {
            return this.storage.remove(TOKEN_KEY).then(() => {
                this.authenticationState.next(false);
                resolve();
            });
        });
    }

    isAuthenticated() {
        return this.authenticationState.value;
    }



}