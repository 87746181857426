import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';
import {ModalController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterPage} from '../../../abstract/RouterPage';
import {Purchase} from '@facepay/api-client/build/facepay/dao/purchase';
import {CurrencyPipe} from '@angular/common';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-states',
  templateUrl: './states.page.html',
  styleUrls: ['./states.page.scss'],
})
export class StatesPage extends RouterPage implements OnInit, AfterViewInit {

    events: any = {};


    constructor(private modalController: ModalController,
                private route: ActivatedRoute,
                private router: Router) {

        super(router, route);

    }


    onEnter() {


      console.dir(this.events);


    }

    ngAfterViewInit() {


    }



    dismiss() {

        this.modalController.dismiss();
    }

    ngOnInit() {





    }



}