import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Facepay } from '../../environments/environment';

import * as encodeUrl from 'encodeurl';

import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;



@Injectable({
    providedIn: 'root'
})
export class AmazonService {


    public tokenKey = 'amazon-token';
    public uidKey = 'amazon-uid';
    public realmKey = 'amazon-realmid';



    private domain = Facepay.domain;
    private url_base = Facepay.amazon;

    private loginUrl = this.url_base + '/auth?scope=profile&redirect=' +  encodeUrl(this.domain + '/amazon-login-callback');
    private registrationUrl = this.url_base + '/auth?scope=profile&redirect=' +  encodeUrl(this.domain + '/amazon-registration-callback');
    private user_url = this.url_base + '/util/user';

    private auth_valid_url = this.url_base + '/util/valid';
    private auth_expire_url = this.url_base + '/util/expire';


    private _uid;


    constructor(
        private storage: Storage,
        private plt: Platform) {

        this.plt.ready().then(() => {
        });


    }


    set(key,token) {
        return this.storage.set(key, JSON.stringify(token));
    }
    get(key) {


        return new Promise((resolve, reject) => {


            this.storage.get(key).then((result) => {

                if ( result ) {

                    resolve(JSON.parse(result));

                } else {
                    resolve()
                }


            }).catch((error)=> {
                reject(null)
            })
        })




    }
    remove(key) {
        return this.storage.remove(key);
    }




    uid(uid) {


        if ( uid ) this._uid = uid;
        else return this._uid;



    }



    private invoke(url) {
        return new Promise(resolve => {
            fetch(url, {
                credentials: "same-origin"
            })
                .then(resp => resp.json())
                .then(result => {
                    resolve(result);
                })
        });
    }

    public valid() {
        return this.invoke(this.auth_valid_url);
    }
    public expire() {
        return this.invoke(this.auth_expire_url);
    }


    public getUser() {
        return this.invoke(this.user_url);
    }

    public async login() {
        await Browser.open({ url: this.loginUrl, windowName: '_self'});
    }

    public async register() {
        await Browser.open({ url: this.registrationUrl, windowName: '_self'});
    }

}


