import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CertifyPage } from './certify.page';

@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      IonicModule,
  ],
  declarations: [CertifyPage], entryComponents: [CertifyPage]
})
export class CertifyPageModule {}
