import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterPage} from '../../../abstract/RouterPage';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-qr',
  templateUrl: './qr.page.html',
  styleUrls: ['./qr.page.scss'],
})
export class QrPage extends RouterPage implements OnInit, AfterViewInit {


    qrCodeUrl: any;
    uuid: any;
    nodash: any;
    bitly: any;

    constructor(private modalController: ModalController,
        private route: ActivatedRoute,
        private router: Router) {

        super(router, route);

    }


    onEnter() {

    }
    ngOnInit() {}
    ngAfterViewInit() {


    }
    dismiss() {

        this.modalController.dismiss();
    }
    openQr() {
        try {
            window.open(this.bitly, '_self')
        } catch (e) {}
    }

}
