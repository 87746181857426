import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WorkingPageRoutingModule } from './working-routing.module';

import { WorkingPage, FormatTimePipe } from './working.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    WorkingPageRoutingModule
  ],
  declarations: [WorkingPage, FormatTimePipe],
  exports: [ FormatTimePipe]
})
export class WorkingPageModule {}
