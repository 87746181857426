import { Component, OnInit } from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';

@Component({
  selector: 'app-selectsubscription',
  templateUrl: './selectsubscription.component.html',
  styleUrls: ['./selectsubscription.component.scss'],
})
export class SelectSubscriptionComponent implements OnInit {


  record;

    constructor (
        private popover: PopoverController
    ) { }




    closePopover(which) {
        this.popover.dismiss({'which': which});
    }

    dismiss() {
        this.popover.dismiss();

    }
  ngOnInit() {}

}

