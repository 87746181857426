import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BankPage } from './bank.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
      ReactiveFormsModule,

      IonicModule,
  ],
  declarations: [BankPage], entryComponents: [BankPage]
})
export class BankPageModule {}
