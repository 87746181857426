import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import {SelectSubscriptionComponent} from './selectsubscription.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    declarations: [SelectSubscriptionComponent],
    entryComponents: [SelectSubscriptionComponent],
    exports: [ SelectSubscriptionComponent]
  })
  export class SelectSubscriptionModule {}