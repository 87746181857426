import {AfterContentInit, Component, OnInit} from '@angular/core';
import {RouterPage} from '../../abstract/RouterPage';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-certify',
  templateUrl: './certify.page.html',
  styleUrls: ['./certify.page.scss'],
})
export class CertifyPage
    extends RouterPage implements OnInit, AfterContentInit {



    business: Business;
    setupdata: any;

    constructor(
        private modalController: ModalController,private route: ActivatedRoute, private router: Router) {
        super(router, route);



    }

    ngOnInit(): void {
    }

    ngAfterContentInit(): void {
    }


    onEnter() {


        this.business = this.router.getCurrentNavigation().extras.state.business;

        console.dir(this.setupdata)

    }

    ionViewDidEnter() {
    }



    onSubmit() {



        this.modalController.dismiss({certify: true});
    }

    onCancel() {
        this.modalController.dismiss();
    }

}
