import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterPage} from '../../../abstract/RouterPage';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.page.html',
  styleUrls: ['./spinner.page.scss'],
})
export class SpinnerPage extends RouterPage implements OnInit, AfterViewInit {



    constructor(private modalController: ModalController,
                private route: ActivatedRoute,
                private router: Router) {

        super(router, route);

    }


    onEnter() {

    }
    ngOnInit() {}
    ngAfterViewInit() {


    }
    dismiss() {

        this.modalController.dismiss();
    }

}
