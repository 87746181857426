import { Component, OnInit, OnDestroy, Injectable } from "@angular/core";
import { timer, Subscription } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";
import {SignupService} from '../../../services/signup.service';

@Injectable({
  providedIn: 'root'
})
export class MyService {
  getCounter(tick) {
    return timer(0, tick);
  }
}

@Component({
  selector: 'app-working',
  templateUrl: './working.page.html',
  styleUrls: ['./working.page.scss'],
  providers: [MyService]
})
export class WorkingPage implements OnInit, OnDestroy {
  businessName; businessGravatar: string;
  countDown: Subscription;
  counter = 60;
  tick = 1000;
  message = ' Do not close this page.   We are configuring your Facepay account with Mart 1. Good news, you will only need to do this once for all the businesses that you pay!';

  constructor(
    private signupService: SignupService,
    private myService: MyService

  ) { }

  ngOnInit() {
    this.businessName = this.signupService.businessName;
    this.businessGravatar = this.signupService.businessgravatar;

    this.countDown = this.myService
      .getCounter(this.tick)
      .subscribe(() => this.counter--);
  }
  ngOnDestroy() {
    this.countDown = null;
  }  

}

@Pipe({
  name: "countDonwFormatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    //MM:SS format
    const minutes: number = Math.floor(value / 60);
    return (
      // ("00" + minutes).slice(-2) +
      // ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2) + " S"
    );

    // for HH:MM:SS
    //const hours: number = Math.floor(value / 3600);
    //const minutes: number = Math.floor((value % 3600) / 60);
    //return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}
